<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">CAJA</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active"
        >Ver Movimientos de Bancos</span
      >
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4><strong>MOVIMIENTOS DE BANCOS</strong></h4>
    </div>
    <div class="col-12">
      <div class="card p-fondo">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-2">
            <span for="sucursal"><strong>SELECCIONE BANCO: </strong></span>
            <Dropdown
              v-model="bancoSelected"
              :options="bancos"
              optionLabel="descripcion"
              optionValue="id"
              placeholder="Seleccione Banco"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-2">
            <span for=""><strong>TIPO MOVIMIENTO: </strong> </span>
            <Dropdown
              autofocus
              v-model="tipo_mov"
              :options="tp_movimientos"
              optionLabel="label"
              placeholder="Seleccione tipo Movimiento"
            />
          </div>
          <div class="field col-12 md:col-2">
            <span for="metodo_pago"
              ><strong>M&Eacute;TODO DE PAGO:</strong>
            </span>
            <Dropdown
              id="metodo_pago"
              v-model="metodo_pagoSelect"
              :options="metodo_pagos"
              optionLabel="nombre"
              optionValue="id"
            />
          </div>
          <div class="field col-12 md:col-2">
            <span for="fecha_inicio"><strong>FECHA INCIO: </strong></span>
            <Calendar
              id="fecha_inicio"
              v-model="fecha_inicio"
              :showIcon="true"
              dateFormat="dd/mm/yy"
              class="p-button-info"
            />
          </div>
          <div class="field col-12 md:col-2">
            <span for="fecha_fin"><strong>FECHA FIN: </strong></span>
            <Calendar
              id="fecha_fin"
              v-model="fecha_fin"
              :showIcon="true"
              dateFormat="dd/mm/yy"
              class="p-button-info"
            />
          </div>
          <div class="field col-12 md:col-2">
            <span for=""><strong>ACCIONES: </strong></span>
            <Button
              label="BUSCAR"
              icon="pi pi-search"
              class="p-button-info"
              v-tooltip.top="'Buscar'"
              :loading="enviando"
              @click="BuscarFiltro"
            />
          </div>
        </div>
      </div>
      <div class="mt-4 card style_card" v-if="mov_bancos.length > 0">
        <div class="p-fluid formgrid grid">
          <table class="table table-bordered table-striped" style="width: 100%">
            <tr>
              <th class="text-center">
                SALDO ANTERIOR:
                <span
                  class="p-badge p-badge-warning p-mr-2"
                  style="font-size: 1rem"
                  >{{
                    convertirNumeroGermanicFormat(saldo_anterior ?? 0)
                  }}
                  Bs.</span
                >
              </th>
              <th class="text-center">
                SALDO ACTUAL:
                <span
                  class="p-badge p-badge-info p-mr-2"
                  style="font-size: 1rem"
                  >{{
                    convertirNumeroGermanicFormat(saldo_actual ?? 0)
                  }}
                  Bs.</span
                >
              </th>
              <th class="text-center">
                TOTAL INGRESO EFECTIVO:
                <span
                  class="p-badge p-badge-success p-mr-2"
                  style="font-size: 1rem"
                  >{{
                    convertirNumeroGermanicFormat(totalIngreso ?? 0)
                  }}
                  Bs.</span
                >
              </th>
              <th class="text-center">
                TOTAL INGRESO QR:
                <span
                  class="p-badge p-badge-success p-mr-2"
                  style="font-size: 1rem"
                  >{{
                    convertirNumeroGermanicFormat(totalIngresoQr ?? 0)
                  }}
                  Bs.</span
                >
              </th>
              <th class="text-center">
                TOTAL EGRESO EFECTIVO:
                <span
                  class="p-badge p-badge-danger p-mr-2"
                  style="font-size: 1rem"
                  >{{
                    convertirNumeroGermanicFormat(totalEgreso ?? 0)
                  }}
                  Bs.</span
                >
              </th>
              <th class="text-center">
                TOTAL EGRESO QR:
                <span
                  class="p-badge p-badge-danger p-mr-2"
                  style="font-size: 1rem"
                  >{{
                    convertirNumeroGermanicFormat(totalEgresoQr ?? 0)
                  }}
                  Bs.</span
                >
              </th>
            </tr>
          </table>
        </div>
      </div>
      <div class="mt-4">
        <DataTable
          ref="dt_mov_banco"
          key="id"
          :value="mov_bancos"
          responsiveLayout="scroll"
          :loading="enviando"
          class="p-datatable-sm"
          :filters="buscar"
          responsive="true"
          stripedRows
          showGridlines
          selectionMode="single click"
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
            >
              <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="buscar['global'].value"
                  placeholder="Buscar..."
                />
              </span>
              <!--  <span class="block mt-2 md:mt-0 p-input-icon-left">
                  <Button
                    v-if="'R_Ventas DescargarPDF' in auth.user.permissions"
                    label="PDF Imprimir"
                    icon="pi pi-file-pdf"
                    class="p-button-outlined p-button-danger mr-2"
                    v-tooltip.top="'Imprimir Reporte PDF'"
                    :loading="generandoPDF"
                    :disabled="generandoPDF"
                    @click="generarPDF"
                  />
                </span>
                <span class="block mt-2 md:mt-0 p-input-icon-left">
                  <Button
                    v-if="'R_Ventas DescargarEXCEL' in auth.user.permissions"
                    label="EXCEL Exportar"
                    icon="pi pi-file-excel"
                    class="p-button-outlined p-button-success"
                    v-tooltip.top="'Imprimir Reporte Excel'"
                    :loading="exportando"
                    :disabled="exportando"
                    @click="exportVentas"
                  />
                </span> -->
            </div>
          </template>
          <template #loading>
            <div class="flex align-items-center justify-content-center">
              <ProgressSpinner />
            </div>
          </template>
          <template #empty>
            <span
              style="
                background-color: #f8d7da;
                padding: 5px;
                border-radius: 5px;
                color: #721c24;
              "
              class="flex align-items-center justify-content-center p-invalid"
              >Debe Seleccionar un Banco y Fechas para Realizar la
              Búsqueda</span
            ></template
          >
          <Column class="text-right" field="id" header="CÓD"> </Column>
          <Column class="text-center" field="fecha" header="FECHA">
            <template #body="{ data }">
              {{ new Date(data.fecha).toLocaleDateString() }}
            </template>
          </Column>
          <Column field="tipo_mov" header="TIPO MOVIMIENTO">
            <template #body="{ data }">
              {{ data.tipo_mov == 1 ? "INGRESO" : "EGRESO" }}
            </template>
          </Column>
          <Column field="nombre_metodo_pago" header="MÉTODO PAGO"></Column>
          <Column field="ingreso" header="INGRESO" class="text-right">
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.ingreso) }}
            </template>
          </Column>
          <Column field="egreso" header="EGRESO" class="text-right">
            <template #body="{ data }">
              {{ convertirNumeroGermanicFormat(data.egreso) }}
            </template>
          </Column>
          <Column field="concepto" header="CONCEPTO"></Column>
          <Column field="nombre_usuario" header="USUARIO"></Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import BancoService from "@/service/BancoService";
import { useAuth } from "@/stores";
import MetodoPagoService from "@/service/MetodoPagoService.js";
import { sum } from "lodash";

export default {
  data() {
    return {
      fecha_inicio: null,
      fecha_fin: null,
      enviando: false,
      mov_bancos: [],
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      bancos: [],
      bancoSelected: 1,
      metodo_pagoSelect: 0,
      metodo_pagos: [{ id: 0, nombre: "TODOS" }],
      tipo_mov: { label: "TODOS", value: 2 },
      tp_movimientos: [
        { label: "TODOS", value: 2 },
        { label: "INGRESO", value: 1 },
        { label: "EGRESO", value: 0 },
      ],
      saldo_anterior: 0,
      saldo_actual: 0,
    };
  },
  bancoService: null,
  auth: null,
  metodoPagoService: null,

  computed: {
    totalIngreso() {
      return sum(
        this.mov_bancos.map((mov) => {
          if (mov.tipo_mov == 1 && mov.metodo_pago_id == 1) {
            return parseFloat(mov.ingreso);
          } else {
            return 0;
          }
        })
      );
    },
    totalIngresoQr() {
      return sum(
        this.mov_bancos.map((mov) => {
          if (mov.tipo_mov == 1 && mov.metodo_pago_id != 1) {
            return parseFloat(mov.ingreso);
          } else {
            return 0;
          }
        })
      );
    },
    totalEgreso() {
      return sum(
        this.mov_bancos.map((mov) => {
          if (mov.tipo_mov == 0 && mov.metodo_pago_id == 1) {
            return parseFloat(mov.egreso);
          } else {
            return 0;
          }
        })
      );
    },
    totalEgresoQr() {
      return sum(
        this.mov_bancos.map((mov) => {
          if (mov.tipo_mov == 0 && mov.metodo_pago_id != 1) {
            return parseFloat(mov.egreso);
          } else {
            return 0;
          }
        })
      );
    },
  },

  created() {
    this.bancoService = new BancoService();
    this.auth = useAuth();
    this.metodoPagoService = new MetodoPagoService();
  },
  mounted() {
    this.cargarBancos();
    this.getMetodoPagos();
  },
  methods: {
    getMetodoPagos() {
      this.metodoPagoService.getAllMetodoPagos().then((response) => {
        this.metodo_pagos = response.metodo_pagos;
        this.metodo_pagos.unshift({ id: 0, nombre: "TODOS" });
      });
    },
    cargarBancos() {
      this.bancoService
        .getBancosAll()
        .then((data) => {
          this.bancos = data.bancos ?? [];
          if (this.bancos.length > 0) this.bancoSelected = this.bancos[0].id;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    BuscarFiltro() {
      this.clientesListadoFiltrado = [];
      this.mov_bancos = [];
      this.enviando = true;
      this.cargarMovBancos();
    },
    cargarMovBancos() {
      this.enviando = true;
      let datos = {
        banco_id: this.bancoSelected,
        fecha_inicio: this.fecha_inicio,
        fecha_fin: this.fecha_fin,
        metodo_pago_id: this.metodo_pagoSelect,
        tipo_mov: this.tipo_mov.value,
      };
      this.bancoService
        .buscarMovBancos(datos)
        .then((data) => {
          this.mov_bancos = data.mov_bancos;
          if (data.mov_bancos.length > 0) {
            this.saldo_anterior = data.mov_bancos[0].saldo_anterior ?? 0;
            this.saldo_actual = data.mov_bancos[0].saldo_actual ?? 0;
          }
          this.enviando = false;
        })
        .catch((error) => {
          this.enviando = false;
          console.log(error);
        });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}

.p-fondo {
  background-color: #7bffae;
}
.style_card {
  border: 1px solid #000000;
  border-bottom: 3px solid #000000;
  padding: 3px;
}
</style>